<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :bordered='false'>
      <a-descriptions :column="13">
        <a-descriptions-item :span="13">
          <a-form-model ref='form' :model='form' layout='inline'>
            <a-form-model-item prop='start'>
              <a-range-picker
                v-model="dateArr"
                :format="[formatType, formatType]"
                :show-time="{
                  hideDisabledOptions: true
                }"
                :disabled-date="disabledDate"
                @change="dateChange"
              />
            </a-form-model-item>
            <a-form-model-item prop='authCode'>
              <a-input v-model='form.authCode' style="width: 350px" placeholder="授权编号" />
            </a-form-model-item>
            <a-form-model-item>
`              <a-button type='primary' @click='handleSubmit'>
                查询
              </a-button>`
            </a-form-model-item>
            <a-form-model-item>
              <a-button @click='handleReset'>
                重置
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-descriptions-item>
        <a-descriptions-item label='运营订单总业绩' :span="13"><span style="color: #E62734;">{{totalAmount}}</span><span style="color: #E62734;padding-left: 50px">{{orderAmount1}}</span></a-descriptions-item>
        <template v-for="(item, index) in record">
          <a-descriptions-item :key="'code' + index" label='商户编号' :span="3">{{item.authCode}}</a-descriptions-item>
          <a-descriptions-item :key="'name' + index" label='' :span="2">{{item.masterName}}</a-descriptions-item>
          <a-descriptions-item :key="'money' + index" label='金额' :span="2">{{item.orderAmount || 0}}</a-descriptions-item>
          <a-descriptions-item :key="'1' + index" label='1'><span style="color: red;">{{item.orderCount1}}</span></a-descriptions-item>
          <a-descriptions-item :key="'2' + index" label='2'><span style="color: red;">{{item.orderCount2}}</span></a-descriptions-item>
          <a-descriptions-item :key="'3' + index" label='3'><span style="color: red;">{{item.orderCount3}}</span></a-descriptions-item>
          <a-descriptions-item :key="'4' + index" label='4'><span style="color: red;">{{item.orderCount4}}</span></a-descriptions-item>
          <a-descriptions-item :key="'5' + index" label='5'><span style="color: red;">{{item.orderCount5}}</span></a-descriptions-item>
          <a-descriptions-item :key="'6' + index" label='6'><span style="color: red;">{{item.orderCount6}}</span></a-descriptions-item>
        </template>
      </a-descriptions>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { getQuotaOrderDetail } from '@/api/member-order'
import moment from 'moment'

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'OrderDetail',

  data() {
    return {
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: baseStart,
        end: baseEnd,
        authCode: ""
      },
      record: [],
    }
  },
  components: { STable, Ellipsis },
  computed: {
    id () {
      return this.$route.query.id;
    },
    totalAmount() {
      let totalAmount = 0;
      if (this.record.length > 0) {
        totalAmount = this.record[0].presidentAmount || 0
      }
      return totalAmount
    },
    orderAmount1() {
      let orderAmount1 = 0;
      if (this.record.length > 0) {
        orderAmount1 = this.record[0].orderAmount1  || 0
      }
      return orderAmount1
    }
  },
  created() {
    this.handleInitial()
  },
  methods: {
    moment,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.handleInitial()
    },
    handleSubmit() {
      this.handleInitial()
    },
    handleInitial() {
      let params = {
        id: this.id
      }
      getQuotaOrderDetail(this.form.authCode ? this.form : Object.assign(params, this.form)).then(result => {
        this.record = result;
      })
    },
  }
}
</script>
